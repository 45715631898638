<template>
  <div class="claim-details-form">
    <BaseHeader :check-mfa="true">
      1.1 {{ $t('claims.new.steps.details-form.header') }}
      <template #intro>{{
        $t('claims.new.steps.details-form.intro')
      }}</template>
    </BaseHeader>
    <div
      class="claim-details-form__grid layout__content layout__content--large"
    >
      <BaseFieldText
        name="wholesaleParty"
        class="claim-details-form__field claim-details-form__field--wholesale-party"
        :value="$store.state.claim.claimData.wholesaleParty"
        @update:modelValue="
          $store.commit('claim/setNewClaimWholesaleParty', $event)
        "
        :label="$t('claims.new.steps.details-form.wholesale-party.label')"
        :placeholder="
          $t('claims.new.steps.details-form.wholesale-party.placeholder')
        "
        rules="required"
      />
      <BaseFieldDateRange
        v-if="showMonthSelector"
        name="claimPeriodMonth"
        class="claim-details-form__field claim-details-form__field--period"
        :value="$store.state.claim.claimData.period"
        @update:modelValue="$store.commit('claim/setNewClaimPeriod', $event)"
        :label="$t('claims.new.steps.details-form.period.label')"
        :placeholder="$t('claims.new.steps.details-form.period.placeholder')"
        :rules="dateRangeFormRules"
        :min-date="minDate || undefined"
        :max-date="maxDate"
        data-test="claim-period"
        :disabled="isAnnual"
      />
      <NewClaimYearSelector
        v-else
        name="claimPeriodYear"
        :begin-date="minDate"
        :end-date="maxDate"
        class="claim-details-form__field claim-details-form__field--period"
        :label="$t('claims.new.steps.details-form.period.label')"
        :placeholder="$t('claims.new.steps.details-form.period.placeholder')"
        @update:modelValue="$store.commit('claim/setNewClaimPeriod', $event)"
        data-test="claim-period"
        rules="required"
        :modelValue="$store.state.claim.claimData.period"
      />
      <BaseFieldText
        name="creditReference"
        class="claim-details-form__field claim-details-form__field--credit-reference"
        :value="$store.state.claim.claimData.creditReference"
        @update:modelValue="
          $store.commit('claim/setNewClaimCreditReference', $event)
        "
        :label="$t('claims.new.steps.details-form.credit-reference.label')"
        :placeholder="
          $t('claims.new.steps.details-form.credit-reference.placeholder')
        "
      />
    </div>
  </div>
</template>

<script lang="ts">
import BaseHeader from '@/components/BaseHeader.vue';
import BaseFieldDateRange from '@/components/form/BaseFieldDateRange.vue';
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import NewClaimYearSelector from '@/components/claims/new-claim/NewClaimYearSelector.vue';
import parse from 'date-fns/parse';
import { differenceInYears } from 'date-fns';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    NewClaimYearSelector,
    BaseHeader,
    BaseFieldDateRange,
    BaseFieldText,
  },
  computed: {
    minDate(): Date | null {
      const dateString =
        this.$store.getters['claim/agreement'].date_time_valid_from;
      const from = dateString
        ? parse(dateString, 'yyyy-MM-dd', new Date(Date.now()))
        : null;
      return from;
    },
    maxDate(): Date {
      const today = new Date(Date.now());
      const dateString =
        this.$store.getters['claim/agreement'].date_time_valid_to;
      const to = parse(dateString, 'yyyy-MM-dd', today);

      // if the agreement period is quarterly the max date should be the last day of the last quarter.
      const maxDate = this.isQuarterly
        ? new Date(
            today.getFullYear(),
            Math.floor(today.getMonth() / 3) * 3,
            -1,
          )
        : today;

      return maxDate < to ? maxDate : to;
    },
    isAnnual(): boolean {
      const { claimPeriod } = this.getAgreementDuration();
      return claimPeriod === 'ANNUAL';
    },
    isQuarterly(): boolean {
      const { claimPeriod } = this.getAgreementDuration();
      return claimPeriod === 'QUARTERLY';
    },
    isMonthly(): boolean {
      const { claimPeriod } = this.getAgreementDuration();
      return claimPeriod === 'MONTHLY' || claimPeriod === null;
    },
    isAgreementShorterOrEqualToOneYear(): boolean {
      const { dateTimeValidFrom, dateTimeValidTo } =
        this.getAgreementDuration();
      const from = parse(dateTimeValidFrom, 'yyyy-MM-dd', new Date(Date.now()));
      const to = parse(dateTimeValidTo, 'yyyy-MM-dd', new Date(Date.now()));
      return differenceInYears(to, from) <= 1;
    },
    showMonthSelector(): boolean {
      return (
        this.isMonthly ||
        this.isQuarterly ||
        (this.isAnnual && this.isAgreementShorterOrEqualToOneYear)
      );
    },
    dateRangeFormRules() {
      if (!this.isQuarterly) {
        return 'required';
      }

      const { dateTimeValidFrom, dateTimeValidTo } =
        this.getAgreementDuration() as {
          dateTimeValidFrom: string;
          dateTimeValidTo: string;
          claimPeriod: string;
        };

      return `required|quarterly:${dateTimeValidFrom},${dateTimeValidTo}`;
    },
  },
  mounted() {
    if (this.showMonthSelector && this.isAnnual) {
      const { dateTimeValidFrom, dateTimeValidTo } =
        this.getAgreementDuration();

      const from = parse(dateTimeValidFrom, 'yyyy-MM-dd', new Date(Date.now()));
      const to = parse(dateTimeValidTo, 'yyyy-MM-dd', new Date(Date.now()));

      const firstOfToMonth =
        to.getDate() !== 1 ? new Date(to.getFullYear(), to.getMonth(), 1) : to;

      this.$store.commit('claim/setNewClaimPeriod', [from, firstOfToMonth]);
    }
  },
  methods: {
    getAgreementDuration(): {
      dateTimeValidFrom: string;
      dateTimeValidTo: string;
      claimPeriod: string;
    } {
      const {
        date_time_valid_from: dateTimeValidFrom,
        date_time_valid_to: dateTimeValidTo,
        claim_period: claimPeriod,
      } = this.$store.getters['claim/agreement'];

      return { dateTimeValidFrom, dateTimeValidTo, claimPeriod };
    },
  },
});
</script>

<style scoped lang="scss">
:deep() .claim-details-form {
  &__grid {
    display: grid;
    grid-gap: 16px;

    @include desktop {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__field {
    margin-bottom: 0.75rem;

    @include desktop {
      &--period {
        grid-column: span 2;
      }

      &--credit-reference {
        grid-column: span 3;
      }
    }
  }
}
</style>
